// src/hooks/useNavigation.js
import { useQuery } from 'react-query';
import {fetchNavigation} from "../api/navigationApi";
import {useDispatch} from "react-redux";
import {setNavigation, setLoading, setError} from "../slice/navigationSlice";

export const useNavigation = () => {
    const dispatch = useDispatch();

    return useQuery('navigation', fetchNavigation, {
        onSuccess: (data) => {
            dispatch(setNavigation(data));
            dispatch(setLoading(false));  // 加載成功後設為 false
        },
        onError: (error) => {
            dispatch(setError(error.message));
            dispatch(setLoading(false));  // 發生錯誤後設為 false
        },
        onSettled: () => {
            dispatch(setLoading(false));
        },
        refetchOnWindowFocus: false,
    });
};
