// src/pages/Case.js
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {ErrorAlert, Footer, Header, NewsNav, Pagination} from "../components";
import {useSuccessStory} from "../hooks/useSuccessStory";
import resource from "../utils/resource";

const Case = () => {
    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 6; // 每頁顯示數量

    const { data, isLoading, isError } = useSuccessStory(pageNumber, pageSize);

    // 確保 data, page, 和 content 存在，避免 undefined 錯誤
    const successStory = data?.content || [];
    const totalPages = data?.totalPages || 0;

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <NewsNav/>
                    <div className="tab-content">
                        <div className="mainInfo">
                            <div className="caseList">
                                <div className="row">
                                    {isError && <ErrorAlert/>}
                                    {!isLoading && successStory.map((article, index) => (
                                        <div key={article.id} className="col-12 col-md-6 col-lg-4">
                                            <figure className="card card_vv">
                                                <div className="card_img">
                                                    <img
                                                        src={resource(article.imgUrl)}
                                                        alt={article.title}
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title">{article.title}</h5>
                                                    <p className="card-text">{article.content}</p>
                                                    <Link to={`/article/${article.id}?type=case`} className="btn">
                                                        查看詳細
                                                    </Link>
                                                </div>
                                            </figure>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Pagination
                                currentPage={pageNumber}
                                totalPages={totalPages}
                                onPageChange={(newPage) => setPageNumber(newPage)}
                                isLoading={isLoading}
                                isError={isError}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default Case;


