// src/App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {
    About,
    Article,
    Case,
    DataCollectionAgreement,
    Download,
    Faq,
    Home,
    Information,
    LatestNews,
    NewsAll,
    NewsArticle,
    PopularNews,
    PrivacyPolicy,
    Search
} from './pages';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/bootstrap-icons.min.css';
import './assets/css/main.css';
import 'animate.css';
import {Analytics, ScrollToTop} from "./components";

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Analytics />
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/popularNews" element={<PopularNews/>}/>
                <Route path="/latestNews" element={<LatestNews/>}/>
                <Route path="/newsAll" element={<NewsAll/>}/>
                <Route path="/information" element={<Information/>}/>
                <Route path="/case" element={<Case/>}/>
                <Route path="/article/:id" element={<Article/>}/>
                <Route path="/newsArticle/:id" element={<NewsArticle/>}/>
                <Route path="/download" element={<Download/>}/>
                <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                <Route path="/search/:keyword" element={<Search/>}/>
                <Route path="/dataCollectionAgreement" element={<DataCollectionAgreement/>}/>
                <Route path="/faq" element={<Faq/>}/>
            </Routes>
        </Router>
    );
}

export default App;
