// src/pages/Information.js
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {ErrorAlert, Footer, Header, NewsNav, Pagination} from "../components";
import {useBusinessInfo} from '../hooks/useBusinessInfo';

const Information = () => {
    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 6; // 每頁顯示數量

    const { data, isLoading, isError } = useBusinessInfo(pageNumber, pageSize);

    // 確保 data, page, 和 content 存在，避免 undefined 錯誤
    const businessInfo = data?.content || [];
    const totalPages = data?.totalPages || 0;

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <NewsNav/>
                    <div className="tab-content">
                        <div className="mainInfo">
                            <div className="informationList">
                                <div className="row">
                                    {isError && <ErrorAlert/>}
                                    {!isLoading && businessInfo.map((article, index) => (
                                        <div key={index} className="col-12 col-md-6">
                                            <div className="infoListItem">
                                                <h5>{article.title}</h5>
                                                <p>{article.content}</p>
                                                <p>{article.releaseDate}</p>
                                                <Link to={`/article/${article.id}?type=information`} className="btn btn-primary">
                                                    顯示全文
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Pagination
                                currentPage={pageNumber}
                                totalPages={totalPages}
                                onPageChange={(newPage) => setPageNumber(newPage)}
                                isLoading={isLoading}
                                isError={isError}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default Information;

