// src/hooks/useSuccessStory.js
import {useQuery} from 'react-query';
import {fetchSuccessStory, fetchSuccessStoryArticle} from '../api/successStoryApi';

export const useSuccessStory = (pageNumber, pageSize) => {
    return useQuery(['successStory', pageNumber, pageSize], () => fetchSuccessStory(pageNumber, pageSize), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
};

export const useSuccessStoryArticle = (id, type) => {
    return useQuery(
        ['successStoryArticle', id],
        () => fetchSuccessStoryArticle(id),
        {
            refetchOnWindowFocus: false,
            enabled: !!id && type === 'case', // Only fetch if id exists and type is 'case'
        }
    );
};