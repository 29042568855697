// src/pages/NewsArticle.js
import React from "react";
import {Link, useParams} from "react-router-dom";
import {Footer, Header, Loading, NoData} from "../components";
import {useArticle, useArticleRecommend} from "../hooks/useNews";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import resource from "../utils/resource";

const NewsArticle = () => {
    const {id} = useParams();
    const { data: newsArticle, isLoading: isArticleLoading } = useArticle(id);
    const { data: recommendedArticles, isLoading: isRecommendedLoading } = useArticleRecommend();

    // 當前頁面的 URL
    const currentUrl = window.location.href;

    // 當 newsArticle 加載中時顯示 Loading
    if (isArticleLoading) {
        return <Loading/>;
    }

    // Configure DOMPurify to remove only unsafe style attributes
    const sanitizedContent = DOMPurify.sanitize(newsArticle.content, {
        FORBID_ATTR: ['style'],
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'p', 'br', 'ul', 'li', 'ol', 'a', 'img'],
        ALLOWED_ATTR: ['href', 'alt', 'src', 'title', 'width', 'height'],
    });

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <div className="mainInfo articleContent">
                            <div className="articleTitle">
                                <h1>{newsArticle.title}</h1>
                                <div className="articleNote">
                                    <span className="left">
                                        <b>{newsArticle.authorName}</b>
                                         <i>{newsArticle.publishDate}</i>
                                    </span>
                                    <span className="right">
                                        <a
                                            href={`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(currentUrl)}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                             <i className="bi bi-line"></i>
                                        </a>
                                        <a
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-facebook"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div className="infoArea">
                                <div className="row">
                                    <div className="col-12 col-xl-9">
                                        <div className="infoList newsList">
                                            <div className="article_con">
                                                <br/>
                                                <br/>
                                                <div dangerouslySetInnerHTML={{__html: parse(sanitizedContent)}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3">
                                        <aside className="articleTag">
                                            <h5>標籤</h5>
                                            <div className="tagList">
                                                {newsArticle.tags && newsArticle.tags.length > 0 ? (
                                                    newsArticle.tags.map(tag => (
                                                        <span key={tag.id} className="tag_item">{tag.name}</span>
                                                    ))
                                                ) : (
                                                    <p>無標籤</p>
                                                )}
                                            </div>
                                            <h5>作者</h5>
                                            <div className="author">
                                                <span className="pic">
                                                    <img
                                                        src={resource(newsArticle.authorImgUrl)}
                                                        alt={newsArticle.authorName}
                                                    />
                                                </span>
                                                <span className="name">{newsArticle.authorName}</span>
                                            </div>
                                            <p>{newsArticle.authorInfo}</p>
                                        </aside>
                                    </div>
                                    <div className="col-12">
                                        <div className="otherArticle">
                                            <div className="">
                                                <h4>推薦文章</h4>
                                                {isRecommendedLoading ? (
                                                    <Loading/>
                                                ) : (
                                                    <div className="otherList list_hh">
                                                        {(recommendedArticles && recommendedArticles.length > 0) ? (
                                                            recommendedArticles.map((article) => (
                                                                <Link to={`/newsArticle/${article.id}`}
                                                                      key={article.id}>
                                                                    <figure>
                                                                        <div className="pic">
                                                                            <img
                                                                                src={resource(article.imgUrl)}
                                                                                alt={article.title}
                                                                            />
                                                                        </div>
                                                                        <figcaption className="text">
                                                                            <h5>{article.title}</h5>
                                                                            <p>{article.content}</p>
                                                                        </figcaption>
                                                                    </figure>
                                                                </Link>
                                                            ))
                                                        ) : (
                                                            <NoData/>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default NewsArticle;

