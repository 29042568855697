// src/api/navigationApi.js
import axios from 'axios';
import endpoints from '../endpoints';

export const fetchNavigation = async () => {
    const response = await axios.get(endpoints.navigationList);
    const data = response.data.menus;

    // 初始化轉換後的結構
    const nestedData = [];
    let currentParent = null;

    data.forEach((item) => {
        if (item.level === 1) {
            // 如果是 level 1，則作為新父節點加入
            currentParent = { title: item.funcName, link: item.funcUrl || '#', type: item.type, subItems: [] };
            nestedData.push(currentParent);
        } else if (item.level === 2 && currentParent) {
            // 如果是 level 2，且有父節點，則作為子節點加入
            currentParent.subItems.push({ title: item.funcName, link: item.funcUrl, type: item.type });
        }
    });

    return nestedData;
};
