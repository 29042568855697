import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NewsNav = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const NEWS_NAV = {
        NEWS_ALL : {
            tabName: 'home',
            path: '/newsAll'
        },
        INFORMATION: {
            tabName: 'profile',
            path: '/information'
        },
        CASE: {
            tabName: 'contact',
            path: '/case'
        }
    }

    // 根據當前路徑來設定 activeTab
    const getActiveTab = () => {
        if (location.pathname === NEWS_NAV.NEWS_ALL.path) return NEWS_NAV.NEWS_ALL.tabName;
        if (location.pathname === NEWS_NAV.INFORMATION.path) return NEWS_NAV.INFORMATION.tabName;
        if (location.pathname === NEWS_NAV.CASE.path) return NEWS_NAV.CASE.tabName;
        // no matching go default
        return NEWS_NAV.NEWS_ALL.tabName;
    };

    const handleTabClick = (tab, path) => {
        navigate(path);
    };

    return (
        <ul className="pageTab nav nav-pills mb-3" id="newsTAB" role="tablist">
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${getActiveTab() === NEWS_NAV.NEWS_ALL.tabName ? "active" : ""}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected={getActiveTab() === NEWS_NAV.NEWS_ALL.tabName}
                    onClick={() => handleTabClick(NEWS_NAV.NEWS_ALL.tabName, NEWS_NAV.NEWS_ALL.path)}
                >
                    新知脈動
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${getActiveTab() === NEWS_NAV.INFORMATION.tabName ? "active" : ""}`}
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected={getActiveTab() === NEWS_NAV.INFORMATION.tabName}
                    onClick={() => handleTabClick(NEWS_NAV.INFORMATION.tabName, NEWS_NAV.INFORMATION.path)}
                >
                    商情資訊
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${getActiveTab() === NEWS_NAV.CASE.tabName ? "active" : ""}`}
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected={getActiveTab() === NEWS_NAV.CASE.tabName}
                    onClick={() => handleTabClick(NEWS_NAV.CASE.tabName, NEWS_NAV.CASE.path)}
                >
                    成功案例
                </button>
            </li>
        </ul>
    );
};

export default NewsNav;
