// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query'; // 引入 QueryClient 和 QueryClientProvider
import App from './App';
import store from './store';
import reportWebVitals from './utils/reportWebVitals';
import ReactGA from 'react-ga4';

// 創建 QueryClient 實例
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log('Environment Variables:', window._env_);

const GA_ID = window._env_?.REACT_APP_GA_ID;
if (GA_ID) {
    console.log(`Initializing GA with ID: ${GA_ID}`);
    ReactGA.initialize(GA_ID);
    ReactGA.send('pageview');

    // 調用 reportWebVitals 並自動發送性能數據到 GA
    reportWebVitals();
} else {
    console.log('GA ID not found');
}

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);

