// src/api/advertiseApi.js
import axios from 'axios';
import endpoints from '../endpoints';

export const fetchAdvertise = async (pageNumber = 0, pageSize = 5) => {
    const response = await axios.get(endpoints.advertiseList, {
        params: {
            pageNumber,
            pageSize,
        },
    });

    const data = response.data.page;

    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
};