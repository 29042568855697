import {ErrorAlert, Footer, Header, Loading, Pagination} from '../components';
import React, {useState} from "react";
import {useSearchList} from "../hooks/useSearch";
import {useParams} from "react-router-dom";

const Search = () => {

    const {keyword} = useParams();
    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 5;

    const { data: searchListData, isLoading: isSearchListLoading, isError: isSearchListError } = useSearchList(keyword, pageNumber, pageSize);

    const searchList = searchListData?.content || [];
    const totalPages = searchListData?.totalPages || 0;

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <h4 className="text-center text-white my-3">搜尋【 <span>{keyword}</span> 】結果</h4>
                        <div className="mainInfo infoContnt">
                            <div className="searchList">
                                <div className="row">
                                    {isSearchListLoading && <Loading/>}
                                    {isSearchListError && <ErrorAlert message={"查詢領域類型異常"}/>}
                                    {!isSearchListLoading && searchList?.map((searchData, index) => (
                                        <div className="col-12" key={searchData.id}>
                                            <div className="searchItem">
                                                {/*如果searchData.urlType === 'newsArticle' href = /newsArticle/${searchData.id}*/}
                                                {searchData.urlType === 'newsArticle' && (
                                                    <a href={`/newsArticle/${searchData.id}`}>
                                                        <h5>{searchData.title}</h5>
                                                        <p>{searchData.content}</p>
                                                    </a>
                                                )}
                                                {/*如果searchData.urlType === 'information' href = /article/${searchData.id}?type=information*/}
                                                {searchData.urlType === 'information' && (
                                                    <a href={`/article/${searchData.id}?type=information`}>
                                                        <h5>{searchData.title}</h5>
                                                        <p>{searchData.content}</p>
                                                    </a>
                                                )}
                                                {/*如果searchData.urlType === 'success' href = /article/${searchData.id}?type=case*/}
                                                {searchData.urlType === 'success' && (
                                                    <a href={`/article/${searchData.id}?type=case`}>
                                                        <h5>{searchData.title}</h5>
                                                        <p>{searchData.content}</p>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <Pagination
                                currentPage={pageNumber}
                                totalPages={totalPages}
                                onPageChange={(newPage) => setPageNumber(newPage)}
                                isLoading={isSearchListLoading}
                                isError={isSearchListError}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Search;
