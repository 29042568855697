// src/hooks/useNews.js
import {useQuery} from 'react-query';
import {
    fetchArticle,
    fetchArticleRecommend,
    fetchCategories,
    fetchCategoryNews,
    fetchLatestNews, fetchListNews,
    fetchPopularNews,
    fetchRecommendNews
} from "../api/newsApi";

export const usePopularNews = () => {
    return useQuery(['popularNews'], () => fetchPopularNews(), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}

export const useListNews = (pageNumber, pageSize) => {
    return useQuery(['listNews', pageNumber, pageSize], () => fetchListNews(pageNumber, pageSize), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}


export const useLatestNews = () => {
    return useQuery(['latestNews'], () => fetchLatestNews(), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}

export const useCategories = () => {
    return useQuery(['categories'], () => fetchCategories(), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}

export const useCategoryNews = (tagId, pageNumber, pageSize) => {
    return useQuery(['categoryNews', tagId, pageNumber, pageSize], () => fetchCategoryNews(tagId, pageNumber, pageSize), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}

export const useRecommendNews = () => {
    return useQuery(['recommendNews'], () => fetchRecommendNews(), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}

export const useArticle = (id) => {
    return useQuery(['newsArticle', id], () => fetchArticle(id), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}

export const useArticleRecommend = () => {
    return useQuery(['articleRecommend'], () => fetchArticleRecommend(), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}
