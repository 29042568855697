// src/pages/Home.js
import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import bgIntroImg from '../assets/img/bg_intro_img.svg';
import bgIm2 from '../assets/img/bg_im_2.gif';
import bgIm3 from '../assets/img/bg_im_3.gif';
import {ErrorAlert, Footer, Header, Loading} from "../components";
import {useSuccessStory} from "../hooks/useSuccessStory";
import {useListNews} from "../hooks/useNews";
import {useActivity} from "../hooks/useActivity";
import {useAdvertise} from "../hooks/useAdvertise";
import {useBanner} from "../hooks/useBanner";
import resource from "../utils/resource";
import {useHomeVideo} from "../hooks/useHomeVideo";
import WOW from 'wow.js';

const Home = () => {
    useEffect(() => {
        // 設置不同的 body class
        document.body.className = "indexbg";

        const wow = new WOW({
                boxClass:     'wow',      // default
                animateClass: 'animated', // default
                offset:       0,          // default
                mobile:       true,       // default
                live:         true        // default
            }
        )
        wow.init();

        // 清除 effect，防止影響其他頁面
        return () => {
            document.body.className = "top_bg"; // 或者設置回預設值
            wow.sync();
        };
    }, []);

    const {data: bannerData, isLoading: isBannerLoading, isError: isBannerError} = useBanner(0, 5);
    const banner = bannerData?.content || [];

    const {data: popularNews, isLoading: isPopularNewsLoading, isError: isPopularNewsError} = useListNews(0, 8);
    const {data: activityData, isLoading: isActivityLoading, isError: isActivityError} = useActivity(0, 5);
    const activityInfo = activityData?.content || [];

    const {data: advertiseData, isLoading: isAdvertiseLoading, isError: isAdvertiseError} = useAdvertise(0, 5);
    const advertise = advertiseData?.content || [];

    const {data: successStoryData, isLoading: isStoryLoading, isError: isStoryError} = useSuccessStory(0, 5);
    const successStory = successStoryData?.content || [];

    const { data: video, isVideoLoading, isVideoError } = useHomeVideo();
    const videoIsOpen = video?.isOpen || false;

    return (
        <div>
            <img src={bgIm2} alt="" className="bg_im_1"/>
            <img src={bgIm2} alt="" className="bg_im_2"/>
            <Header/>
            <div className="wrapper indexWrap">
                <section className="indexVideo">
                    {isVideoLoading && <Loading/>}
                    {isVideoError && <ErrorAlert/>}
                    {videoIsOpen ? (
                        <video width="100%" autoPlay loop muted playsInline poster={video.imageUrl}>
                            <source src={video.videoUrl} type="video/mp4"/>
                            您的瀏覽器不支援此影片格式。
                        </video>
                    ) : <></>}
                </section>
                <section>
                    {isBannerLoading && <Loading/>}
                    {isBannerError && <ErrorAlert/>}
                    {banner && banner.length > 0 ? (
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {banner.map((_, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                        aria-current={index === 0 ? "true" : "false"}
                                        aria-label={`Slide ${index + 1}`}
                                    ></button>
                                ))}
                            </div>
                            <div className="carousel-inner">
                                {banner.map((item, index) => (
                                    <div key={item.id} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                        <a href={item.linkUrl} target="_blank" rel="noopener noreferrer">
                                            <picture>
                                                <source media="(min-width: 768px)" srcSet={resource(item.imgUrl)}/>
                                                <source media="(max-width: 767px)" srcSet={resource(item.imgUrl)}/>
                                                <img
                                                    src={resource(item.imgUrl)}
                                                    alt={item.name}
                                                    className="d-block w-100"
                                                />
                                            </picture>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button"
                                    data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </section>

                <section className="bg_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <img src={bgIntroImg} alt="" className="img-fluid wow fadeInUp"
                                     data-wow-duration="1.5s"/>
                            </div>
                            <div className="col-12 col-md-6">
                                <h2 className="wow fadeInUp" data-wow-duration="1.5s"
                                    data-wow-delay=".5s">數位電商基地</h2>
                                <h5 className="wow fadeInUp" data-wow-duration="1.5s"
                                    data-wow-delay=".8s">您的一站式電商資源補給站</h5>
                                <p className="wow fadeInUp" data-wow-duration="1.5s"
                                   data-wow-delay="1s">想要做電商，卻不知從何開始嗎？經營電商不只是通路的轉換，而是一個完全改變營運思維的過程，需要持續的嘗試與學習，更需要有專業知識與資源的支持。想開創國際銷售新局勢，跨足電商市場嗎？讓「數位電商基地」成為您最強的後盾吧！</p>
                            </div>
                        </div>
                    </div>
                    <div className="marquee">
                        <div>
                            <span className="text1">digital marketing@</span>
                            <span className="text2">taiwantrade #</span>
                            <span className="text1">digital marketing@</span>
                            <span className="text2">taiwantrade #</span>
                        </div>
                        <div>
                            <span className="text1">digital marketing@</span>
                            <span className="text2">taiwantrade #</span>
                            <span className="text1">digital marketing@</span>
                            <span className="text2">taiwantrade #</span>
                        </div>
                    </div>
                </section>

                <section className="bg_news">
                    <div className="container">
                        <h2 className="text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">不能錯過的新知
                            <div className="lll">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </h2>
                        <div className="row wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".7s">
                            {isPopularNewsLoading && <Loading/>}
                            {isPopularNewsError && <ErrorAlert/>}
                            {popularNews && popularNews.length > 0 ? (
                                popularNews.map((news, index) => (
                                    <div className="col-12 col-md-6 col-xl-3" key={index}>
                                        <figure className="card card_vv">
                                            <Link to={`/newsArticle/${news.id}`}>
                                                <div className="card_img">
                                                    <img
                                                        src={resource(news.imgUrl)}
                                                        alt={news.title}
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title">{news.title}</h5>
                                                    <p className="card-text">{news.content || "內容摘要未提供"}</p>
                                                </div>
                                            </Link>
                                        </figure>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center">暫無熱門新聞</p>
                            )}
                            <div className="text-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".7s">
                                <Link to="/popularNews" className="btn btn-lg btn-outline-light mx-auto">
                                    了解更多新知 <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg_information wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                    <img src={bgIm3} alt="" className="bg_im_3"/>
                    <img src={bgIm3} alt="" className="bg_im_4"/>
                    <div className="container">
                        <div className="row">
                            <h2 className="wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".5s">活動資訊
                                <div className="lll">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </h2>
                            <div className="col-12 col-md-12 col-lg-8">
                                <div className="information_box wow fadeInUp" data-wow-duration="1.5s"
                                     data-wow-delay=".5s">
                                    <ul>
                                        {isStoryLoading && <Loading/>}
                                        {isStoryError && <ErrorAlert/>}
                                        {activityInfo.length > 0 ? (
                                            activityInfo.map((activity) => (
                                                <li key={activity.id}>
                                                    <a href={activity.url} target="_blank" rel="noopener noreferrer">
                                                        <h5>{activity.title}</h5>
                                                        <p>
                                                            <span><i
                                                                className="bi bi-calendar4-week"></i>{activity.eventPeriod}</span>
                                                            <span><i
                                                                className="bi bi-geo-alt"></i>{activity.address}</span>
                                                        </p>
                                                    </a>
                                                </li>
                                            ))
                                        ) : (
                                            <p className="text-center">暫無活動資訊</p>
                                        )}
                                    </ul>
                                    <div className="text-center">
                                        <a href="https://events.taiwantrade.com/"
                                           className="btn btn-lg btn-outline-info mx-auto">
                                            了解更多活動 <i className="bi bi-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 information_banner wow fadeInUp"
                                 data-wow-duration="1.5s" data-wow-delay=".8s">
                                {isAdvertiseLoading && <Loading/>}
                                {isAdvertiseError && <ErrorAlert/>}
                                {advertise.length > 0 ? (
                                    advertise.map((ad) => (
                                        <a key={ad.id} href={ad.linkUrl} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={resource(ad.imgUrl)}
                                                alt={ad.name}
                                                className="img-fluid my-1"/>
                                        </a>
                                    ))
                                ) : (
                                    <p className="text-center">暫無廣告</p>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <div className="marquee marquee_md">
                    <div>
                        <span className="text1">digital marketing@</span>
                        <span className="text2">taiwantrade #</span>
                        <span className="text1">digital marketing@</span>
                        <span className="text2">taiwantrade #</span>
                    </div>
                    <div>
                        <span className="text1">digital marketing@</span>
                        <span className="text2">taiwantrade #</span>
                        <span className="text1">digital marketing@</span>
                        <span className="text2">taiwantrade #</span>
                    </div>
                </div>

                <section className="bg_case">
                    <div className="container">
                        <h2 className="text-center wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".5s">成功案例
                            <div className="lll">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </h2>
                        <div className="caseList wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".5s">
                            <div className="caseItem wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".5s">
                                {isActivityLoading && <Loading/>}
                                {isActivityError && <ErrorAlert/>}
                                {successStory.length > 0 ? (
                                    successStory.map((story, index) => (
                                        <figure className="card card_vv" key={story.id}>
                                            <a href={`/article/${story.id}?type=case`}>
                                                <div className="card_img">
                                                    <img
                                                        src={resource(story.imgUrl)}
                                                        alt={story.title}
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <p className="card-text">{story.title || "公司名稱未提供"}</p>
                                                </div>
                                            </a>
                                        </figure>
                                    ))
                                ) : (
                                    <p className="text-center">暫無成功案例</p>
                                )}
                            </div>
                            <div className="text-center">
                                <Link to="/case" className="btn btn-lg btn-outline-light mx-auto">
                                    了解更多成功案例 <i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="ttt">
                        Introduction and content of successful cases<i className="bi bi-arrow-up-right"></i><i
                        className="bi bi-arrow-up-right"></i><i className="bi bi-arrow-up-right"></i>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
};

export default Home;
