// src/pages/NewsArticle.js
import React, {useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import {ErrorAlert, Footer, Header, NewsNav, NoData, PageNav, Pagination} from "../components";
import {useCategories, useCategoryNews, useRecommendNews} from "../hooks/useNews";
import {categories} from '../utils/categories';
import resource from "../utils/resource";

const NewsAll = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tagId = searchParams.get("tagId");

    const {data: subCategories} = useCategories();
    const selectedSubCategory = subCategories?.find(sub => sub.id === tagId);
    const subCategoryTitle = selectedSubCategory ? selectedSubCategory.name : "全部文章";

    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 6; // 每頁顯示數量

    const {data: page, isLoading, isError} = useCategoryNews(tagId, pageNumber, pageSize);
    const articles = page?.content || [];
    const totalPages = page?.totalPages || 0;

    const {data: recommendedArticles} = useRecommendNews();

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <NewsNav/>
                    <div className="tab-content">
                        <div className="mainInfo infoContnt">
                            <div className="infoArea infoList">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <PageNav categories={categories} selectedCategory="分類文章"/>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="infoList newsList">
                                            <h4 className="article_title">{subCategoryTitle}</h4>
                                            <div className="row">
                                                {isError && <ErrorAlert/>}
                                                {!isLoading && articles.map((article, index) => (
                                                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                                                        <figure className="card card_vv">
                                                            <div className="card_img">
                                                                <img
                                                                    src={resource(article.imgUrl)}
                                                                    alt={article.title}
                                                                />
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="card-title">{article.title}</h5>
                                                                <p className="card-text">{article.content}</p>
                                                                <Link to={`/newsArticle/${article.id}`}
                                                                      className="btn">閱讀文章</Link>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                ))}
                                            </div>
                                            <Pagination
                                                currentPage={pageNumber}
                                                totalPages={totalPages}
                                                onPageChange={(newPage) => setPageNumber(newPage)}
                                                isLoading={isLoading}
                                                isError={isError}
                                            />
                                            <div className="otherArticle">
                                                <div>
                                                    <h4>推薦文章</h4>
                                                    <div className="otherList">
                                                        {(recommendedArticles && recommendedArticles.length > 0) ? (
                                                            recommendedArticles.map((article, index) => (
                                                                <Link to={`/newsArticle/${article.id}`} key={index}>
                                                                    <h5><i>{index + 1}</i>{article.title}</h5>
                                                                    <p>{article.content}</p>
                                                                </Link>
                                                            ))
                                                        ) : (
                                                            <NoData/>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default NewsAll;
