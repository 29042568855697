import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname, search } = useLocation(); // 同時監聽 pathname 和 search

    useEffect(() => {
        console.log('Current Path:', pathname + search); // 調試用
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 平滑滾動
        });
    }, [pathname, search]); // 監聽 search 的變化

    return null; // 不渲染任何東西
};

export default ScrollToTop;