// src/pages/DataCollectionAgreement.js
import React from 'react';
import {Footer, Header} from '../components';

const DataCollectionAgreement = () => {
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <div className="mainInfo articleContent about_page">
                            <div className="articleTitle pt-3 pb-0">
                                <h1>個人資料保護聲明</h1>
                            </div>
                            <div className="infoArea">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="infoList newsList">
                                            <section className="row article_con about_con">
                                                <p>中華民國對外貿易發展協會（包含具有關係企業關係之法人或組織團體，如台灣貿易中心等，詳 <a
                                                    href="https://www.taitra.org.tw/iframe.aspx?n=10">官方網站
                                                </a>，以下統稱「本會」）尊重並致力保護您的個人隱私及個人資料，訂有「<a
                                                    href="https://gd.taiwantrade.com/privacy-policy"> 隱私權政策
                                                </a>」，並依個人資料保護法第8條規定，告知以下事項：</p>
                                                <h3><strong>1. 蒐集目的</strong></h3>
                                                <p>協助廠商發展中華民國對外貿易、其他公共部門（包括行政法人、政府捐助財團法人及其他公法人）執行相關業務；貿易推廣及管理；網路購物及其他電子商務服務；客戶管理與服務；中小企業及其他產業之輔導；政令宣導；教育或訓練行政；圖書出版品管理；廣告或商業行為管理；行銷；契約、類似契約或其他法律關係事務；商業與技術資訊；資(通)訊服務；資(通)訊與資料庫管理；資通安全與管理；調查、統計與研究分析；以及非公務機關依法定義務所進行個人資料之蒐集處理及利用等。(通)訊服務；資(通)訊與資料庫管理；資通安全與管理；調查、統計與研究分析；以及非公務機關依法定義務所進行個人資料之蒐集處理及利用等。
                                                </p>
                                                <h3><strong>2. 個人資料類別</strong></h3>
                                                <p>識別類、社會情況、及/或其他各類依蒐集目的所必要之個人資料等。</p>
                                                <h3><strong>3. 個人資料處理、利用之期間、地區、對象及方式</strong></h3>
                                                <p>(一) 期間：<br/>個人資料蒐集之特定目的存續期間、或依相關法令或契約約定之保存年限、或本會因執行業務所必須之保存期間（較法令或契約約定期間長者），或另經您同意之較長期間。<br/><br/>(二)
                                                    地區：<br/>包括中華民國、本會各駐點及其轄區之國家、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者所在地、個人資料利用對象之所在地，以及本會業務往來機構之所在地。<br/><br/>(三)
                                                    對象：<br/>您的資料可能會提供給本會的代理人、簽約人、及合作夥伴（例如與本會合作的電商環節服務商與顧問）、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者、其他與本會有業務往來之機構、法院、及/或政府主管機關。<br/><br/>(四)
                                                    方式：<br/>以自動化機器或其他非自動化之方式為蒐集、處理、利用與國際傳輸。
                                                </p>
                                                <h3><strong>4. 依個人資料保護法規定得行使之權利與方式</strong></h3>
                                                <p>就您的個人資料，依個人資料保護法規定，您有權：<br/><br/>(1)查詢或請求閱覽、及請求製給複製本，本會得酌收必要成本費用<br/><br/>(2)請求補充或更正，惟依法您應為適當之釋明<br/><br/>(3)請求停止蒐集、處理或利用、及請求刪除，但因本會執行職務或業務所必須者，得依法不依您的請求為之<br/><br/>(4)如利用您的個人資料行銷時，您得表示拒絕接受行銷。如您欲行使上述權利，敬請email至
                                                    member@taiwantrade.com 或致 電台灣經貿網免付費客服專線(0800-506-
                                                    088)。<br/><br/></p>
                                                <h3><strong>5. 不提供個人資料所致權益之影響</strong></h3>
                                                <p>如不同意提供資料，將無法接受本會所提供之服務。</p>
                                                <h3><strong>6.
                                                    已確認所提供之個人資料真實且正確；如事後有異動情形，將即檢具相關證明文件送交本會辦理更正。</strong>
                                                </h3>
                                                <p><br/><br/></p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default DataCollectionAgreement;