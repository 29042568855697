// src/api/successStoryApi.js
import axios from 'axios';
import endpoints from '../endpoints';

export const fetchSuccessStory = async (pageNumber = 0, pageSize = 5) => {
    const response = await axios.get(endpoints.successStoryList, {
        params: {
            pageNumber,
            pageSize,
        },
    });

    const data = response.data.page;

    // 返回分頁資訊和內容
    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
};

export const fetchSuccessStoryArticle = async (id = undefined) => {
    if (!id) {
        throw new Error('SuccessStory Article ID is required');
    }

    const response = await axios.get(`${endpoints.successStoryArticle}/${id}`);
    return response.data;
};