// src/utils/reportWebVitals
// CLS (Cumulative Layout Shift)：視覺穩定性指標，測量頁面加載時的布局移動程度（例如圖片或內容在加載過程中位置發生變動）。
// FID (First Input Delay)：互動性指標，測量頁面在第一次用戶交互（如點擊按鈕）後的延遲時間。
// FCP (First Contentful Paint)：內容加載指標，測量頁面上第一個內容（如文字、圖片）顯示的時間。
// LCP (Largest Contentful Paint)：主要內容加載指標，測量頁面上最大內容塊（如圖片或文字）顯示的時間。
// TTFB (Time to First Byte)：伺服器響應指標，測量從用戶請求頁面到瀏覽器收到第一個位元組的時間。
import ReactGA from 'react-ga4';

const sendToAnalytics = (metric) => {
  ReactGA.event({
    category: 'Web Vitals',
    action: metric.name,
    value: Math.round(metric.value), // 將數據四捨五入
    label: metric.id, // 唯一標識
    nonInteraction: true, // 不計入用戶互動
  });
};

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// 將性能數據發送到 Google Analytics
reportWebVitals(sendToAnalytics);

export default reportWebVitals;