// src/api/activityApi.js
import axios from 'axios';
import endpoints from '../endpoints'

export const fetchActivity = async (pageNumber = 0, pageSize = 5) => {
    const response = await axios.get(endpoints.activityList, {
        params: {
            pageNumber,
            pageSize,
        },
    });

    const data = response.data.page;

    // 返回分頁資訊和內容
    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
};