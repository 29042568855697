// src/pages/NoData.js
import React from 'react';
import { Alert } from 'react-bootstrap';

const NoData = () => (
    <Alert variant="warning" className="text-center my-4">
        無查詢結果.
    </Alert>
);

export default NoData;