// src/components/Header.js
import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Loading} from "./index";
import {useNavigation} from "../hooks/useNavigation";
import logoColor from '../assets/img/logo-color.f50f778.svg';
import logoTT from '../assets/img/logo-tt.0945799.svg';
import {$} from 'react-jquery-plugin'
import "sidr/dist/jquery.sidr.min";

const Header = () => {
    const menuToggleRef = useRef(null);
    const sidrRef = useRef(null);
    const searchTopRef = useRef(null);
    const searchInputRef = useRef(null);
    const isMobile = () => window.innerWidth <= 767.98;

    const navItems = useSelector(state => state.navigation.data);
    const loading = useSelector(state => state.navigation.loading);
    const error = useSelector(state => state.navigation.error);

    useNavigation();  // 自動處理 navigation 請求

    useEffect(() => {
        const handleResize = () => {
            if (isMobile()) {
                // Initialize sidr plugin only for mobile view
                if (!$(menuToggleRef.current).data('sidr-initialized')) {
                    $.sidr('close', 'sidr');

                    // Bind sidr to the toggle button
                    $(menuToggleRef.current).sidr({
                        name: 'sidr',
                        side: 'left',
                        speed: 200,
                        displace: false,
                        method: 'toggle',
                        bind: 'touchstart click',
                        onOpen: handleOpen,
                        onClose: handleClose
                    });
                    $(menuToggleRef.current).data('sidr-initialized', true);
                }

            } else {
                // Cleanup if viewport exceeds mobile width
                $.sidr('close', 'sidr');
                sidrRef.current.className = ''; // Remove all classes
                $(menuToggleRef.current).removeData('sidr-initialized');
            }
        };

        const handleOpen = () => {
            $(menuToggleRef.current).addClass('active');
        };

        const handleClose = () => {
            $(menuToggleRef.current).removeClass('active');
        };

        // Initial check on load
        handleResize();
        // fix: 修正UIUX提供版面非預期運作
        $(searchTopRef.current).css('visibility', 'visible');

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Event binding for .no_link elements
        $('.navBar_list .no_link').on('click', function (event) {
            event.preventDefault();
            $(this).toggleClass('rotate180');
            $(this).siblings('.down_menu').toggle(200).addClass('downdown'); // Toggle .down_menu visibility
        });

        // Cleanup on component unmount
        return () => {
            $('.navBar_list .no_link').off('click');
        };
    }, [navItems]);

    if (loading) {
        return <Loading/>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <header>
            <div className="navBar navbar-expand-lg">
                <div className="logo order-2 order-lg-1">
                    <Link to={`/`} className="navbar-brand"> <img src={logoColor} alt="Logo Color"/></Link>
                    <a className="navbar-brand" href="https://info.taiwantrade.com/">
                        <img src={logoTT} alt="Taiwan Trade"/>
                    </a>
                </div>
                <div className="mainNav order-1 order-lg-2">
                    <div id="menu-toggle" className="menu-icon" ref={menuToggleRef}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div id="sidr" ref={sidrRef}>
                        <ul className="navBar_list">
                            {navItems.map((item, index) => (
                                <li className="nav-item" key={"navBar_list_nav-item" + index}>
                                    <a
                                        className={`nav-link${item.subItems && item.subItems.length > 0 ? ' no_link' : ''}`}
                                        href={item.link || '#'}
                                        role="button"
                                        target={item.link?.startsWith('/') ? '_self' : '_blank'}
                                    >
                                        {item.title} {item.subItems.length > 0 && <i className="bi bi-caret-down"></i>}
                                    </a>
                                    {item.subItems && item.subItems.length > 0 && (
                                        <ul className="down_menu">
                                            {item.subItems.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <a className="dropdown-item" href={subItem.link}
                                                       target={subItem.link?.startsWith('/') ? '_self' : '_blank'}
                                                       rel="noopener noreferrer"
                                                    >
                                                        {subItem.title}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <button className="btn order-3 order-lg-3 topSearchBTN" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#searchTop" aria-controls="searchTop">
                    <i className="bi bi-search"></i>
                </button>
            </div>

            <div className="offcanvas offcanvas-top" tabIndex="-1" id="searchTop" ref={searchTopRef}
                 aria-labelledby="offcanvasTopLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasTopLabel">全站搜尋</h5>
                    <button type="button" className="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                        <input className="form-control" type="search" placeholder="請輸入關鍵字..." aria-label="Search"
                               ref={searchInputRef}/>
                        <button className="btn search_btn" type="submit" id="searchClose" onClick={() => {
                            if (searchInputRef.current.value.trim() !== '') {
                                window.location.href = `/search/${searchInputRef.current.value}`;
                            } else {
                                alert('請輸入關鍵字');
                            }
                        }}>搜尋
                        </button>
                    </form>
                </div>
            </div>
        </header>
    );
};

export default Header;
