// src/components/LatestNews.js
import React from "react";
import {Link} from 'react-router-dom';
import {ErrorAlert, Footer, Header, Loading, NewsNav, PageNav} from "../components";
import {usePopularNews} from "../hooks/useNews";
import {categories} from '../utils/categories';
import resource from "../utils/resource";

const PopularNews = () => {
    const { data, isLoading, isError } = usePopularNews();
    const articles = data;

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <NewsNav/>
                    <div className="tab-content">
                        <div className="mainInfo infoContnt">
                            <div className="infoArea infoList">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <PageNav categories={categories} selectedCategory="熱門文章"/>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="infoList newsList">
                                            <div className="row">
                                                {isLoading && <Loading/>}
                                                {isError && <ErrorAlert/>}
                                                {!isLoading && articles.map((article, index) => (
                                                    <div className="col-12" key={index}>
                                                        <figure className="card card_hh">
                                                            <div className="card_img">
                                                                <span className="rank">{index + 1}</span>
                                                                <img
                                                                    src={resource(article.imgUrl)}
                                                                    alt={article.title}
                                                                />
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="card-title">{article.title}</h5>
                                                                <p className="card-text">{article.content}</p>
                                                                <Link to={`/newsArticle/${article.id}`}
                                                                      className="btn">閱讀文章</Link>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PopularNews;
