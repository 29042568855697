// src/pages/Article.js
import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {ErrorAlert, Footer, Header, Loading} from "../components";
import {useSuccessStoryArticle} from "../hooks/useSuccessStory";
import {useBusinessInfoArticle} from "../hooks/useBusinessInfo";
import resource from "../utils/resource";

const Article = () => {
    const {id} = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');

    const { data: businessArticle, isLoading: isBusinessLoading, error: businessError } = useBusinessInfoArticle(id, type);
    const { data: successArticle, isLoading: isSuccessLoading, error: successError } = useSuccessStoryArticle(id, type);

    // Determine loading, error, and article data based on the type
    const isLoading = type === 'information' ? isBusinessLoading : isSuccessLoading;
    const error = type === 'information' ? businessError : successError;
    const article = type === 'information' ? businessArticle : successArticle;

    if (isLoading) {
        return <Loading />;
    }

    // 當前頁面的 URL
    const currentUrl = window.location.href;

    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <div className="mainInfo articleContent article_s">
                            {error || !article ? (
                                <ErrorAlert message={error ? "載入標籤時發生錯誤。" : "無法取得文章"} />
                            ) : (
                                <>
                                    <div className="articleTitle">
                                        <h1>{article.title}</h1>
                                        <div className="articleNote">
                                            <span className="left">
                                                <b>{article.author}</b>
                                                {type !== 'case' && <i>{article.releaseDate}</i>}
                                            </span>
                                            <span className="right">
                                                <a
                                                    href={`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(currentUrl)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="bi bi-line"></i>
                                                </a>
                                                <a
                                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="bi bi-facebook"></i>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="infoArea">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="infoList newsList">
                                                    <div className="article_con">
                                                        {type === 'case' && (
                                                            <img
                                                                src={resource(article.imgUrl)}
                                                                alt={article.title}
                                                            />
                                                        )}
                                                        <p>{article.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Article;
