// src/pages/Download.js
import React, {useEffect, useState} from 'react';
import {useDownloadList, useDownloadTags} from "../hooks/useDownload";
import {ErrorAlert, Footer, Header, Loading, Pagination} from "../components";
import resource from "../utils/resource";

const Download = () => {
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const pageSize = 5;

    const { data: tags, isTagsLoading, isTagsError } = useDownloadTags();
    const { data: downloadListData, isLoading: isDownloadListLoading, isError: isDownloadListError } = useDownloadList(selectedTags.join(','), searchQuery, pageNumber, pageSize);

    const handleDownload = async (fileUrl, filename) => {
        try {
            const response = await fetch(fileUrl, { method: 'GET', mode: 'cors' });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            // Clean up by removing the link and revoking the blob URL
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const handleTagChange = (id) => {
        setSelectedTags((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((tag) => tag !== id) : [...prevSelected, id]
        );
    };

    const handleClearAll = () => {
        setSelectedTags([]);
        setSearchQuery('');
        setSearchText('');
    };

    const handleSearchSubmit = () => {
        setSearchQuery(searchText);
    };

    const downloadList = downloadListData?.content || [];
    const totalPages = downloadListData?.totalPages || 0;
    const totalElements = downloadListData?.totalElements || 0;

    useEffect(() => {
        setPageNumber(0);
    }, [selectedTags, searchQuery]);

    return (
        <div>
            <Header />
            <div className="wrapper">
                <div className="container">
                    <ul className="pageTab nav nav-pills mb-3" id="download" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">檔案下載區
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="mainInfo infoSaerch">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                 aria-labelledby="pills-home-tab">
                                <form className="searchForm mt-3" onSubmit={(e) => e.preventDefault()}>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <h2 className="h5 text-primary">專業領域類型</h2>
                                            <div className="row">
                                                {isTagsLoading && <Loading/>}
                                                {isTagsError && <ErrorAlert message={"查詢領域類型異常"}/>}
                                                {!isTagsLoading && tags?.map((tag, index) => (
                                                    <div className="col-12 col-md-3" key={tag.id}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={tag.id}
                                                                id={`checkbox${index}`}
                                                                onChange={() => handleTagChange(tag.id)}
                                                                checked={selectedTags.includes(tag.id)}
                                                            />
                                                            <label className="form-check-label"
                                                                   htmlFor={`checkbox${index}`}>
                                                                {tag.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="searchGroup input-group d-flex">
                                                <input
                                                    className="form-control me-2"
                                                    type="search"
                                                    placeholder="Search"
                                                    aria-label="Search"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                                <button className="btn" type="button" onClick={handleSearchSubmit}>
                                                    <i className="bi bi-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* 搜尋結果內容 */}
                        <div className="mainInfo infoContnt">
                            <div className="tagGroup">
                                <div>篩選後共有<span className="data_num">{totalElements}</span>筆活動，篩選條件</div>
                                <div className="tagList">
                                    {selectedTags.map(tagId => {
                                        const tag = tags.find(tag => tag.id === tagId);
                                        return (
                                            <span key={tagId} className="tag_item">
                                                {tag?.name} <i className="bi bi-x-lg" onClick={() => handleTagChange(tagId)}></i>
                                            </span>
                                        );
                                    })}
                                </div>
                                <button type="button" className="btn_clearAll btn btn-sm btn-primary"
                                        onClick={handleClearAll}>全部清除
                                </button>
                            </div>

                            <div className="infoArea infoList">
                                <div className="row">
                                    {isDownloadListError && <ErrorAlert message={"查詢錯誤"}/>}
                                    {downloadList.map((file) => (
                                        <div className="col-12 col-md-6" key={file.id}>
                                            <div className="downloadBtn">
                                                <div>
                                                    <h5>{file.title}</h5>
                                                    <p>{file.filename}</p>
                                                </div>
                                                <div>
                                                    <a onClick={() => handleDownload(resource(file.fileUrl), file.filename)}>
                                                        <i className="bi bi-cloud-arrow-down"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <Pagination
                                    currentPage={pageNumber}
                                    totalPages={totalPages}
                                    onPageChange={(newPage) => setPageNumber(newPage)}
                                    isLoading={isDownloadListLoading}
                                    isError={isDownloadListError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Download;
