// src/hooks/useDownload.js
import {useQuery} from "react-query";
import {fetchDownloadList, fetchDownloadTags} from "../api/downloadApi";

export const useDownloadTags = () => {
    return useQuery(['downloadTags'], () => fetchDownloadTags(), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
};

export const useDownloadList = (tagIds, searchText, pageNumber, pageSize) => {
    return useQuery(['downloadList', tagIds, searchText, pageNumber, pageSize], () => fetchDownloadList(tagIds, searchText, pageNumber, pageSize), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
}