// src/api/searchApi.js
import axios from 'axios';
import endpoints from '../endpoints';

export const fetchSearchList = async (keyword, pageNumber, pageSize) => {
    const response = await axios.get(endpoints.searchList, {
        params: {
            keyword,
            pageNumber,
            pageSize,
        },
    });

    const data = response.data.page;

    // 返回分頁資訊和內容
    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
};
