import React from 'react';
import {Loading, NoData} from "./index";
import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages, onPageChange, isLoading, isError }) => {
    const handlePrevious = () => {
        if (currentPage > 0) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            onPageChange(currentPage + 1);
        }
    };

    const generatePageNumbers = () => {
        const pages = [];
        const range = 2; // Number of pages to show before and after the current page

        const startPage = Math.max(0, currentPage - range);
        const endPage = Math.min(totalPages - 1, currentPage + range);

        if (startPage > 0) pages.push(0); // Always include the first page
        if (startPage > 1) pages.push("..."); // Ellipsis before the range

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (endPage < totalPages - 2) pages.push("..."); // Ellipsis after the range
        if (endPage < totalPages - 1) pages.push(totalPages - 1); // Always include the last page

        return pages;
    };

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return '';
    }

    if (totalPages === 0) {
        return <NoData />;
    }

    const pageNumbers = generatePageNumbers();

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
                {currentPage > 0 && (
                    <li className="page-item goto_page">
                        <button className="page-link" onClick={handlePrevious}>
                            <i className="bi bi-arrow-left"></i>
                        </button>
                    </li>
                )}

                {pageNumbers.map((page, index) => (
                    <li
                        key={`${page}-${index}`}
                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                    >
                        {typeof page === 'number' ? (
                            <button className="page-link" onClick={() => onPageChange(page)}>
                                {page + 1}
                            </button>
                        ) : (
                            <span className="page-link">{page}</span>
                        )}
                    </li>
                ))}

                {currentPage < totalPages - 1 && (
                    <li className="page-item goto_page">
                        <button className="page-link" onClick={handleNext}>
                            <i className="bi bi-arrow-right"></i>
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
};

export default Pagination;
