// src/features/navigationSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { mockNavData } from '../utils/mock/mockNavData';

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        data: mockNavData,  // 使用 mock 資料作為初始值
    },
    reducers: {
        setNavigation: (state, action) => {
            state.data = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },

    },
});

export const { setNavigation,setLoading, setError } = navigationSlice.actions;
export default navigationSlice.reducer;
