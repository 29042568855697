// src/api/downloadApi.js
import axios from 'axios';
import endpoints from '../endpoints';

export const fetchDownloadTags = async () => {
    const response = await axios.get(endpoints.downloadTags);

    return response.data.downloadTags;
};


export const fetchDownloadList = async (tagIds, searchText, pageNumber, pageSize) => {
    const response = await axios.get(endpoints.downloadList, {
        params: {
            tagIds,
            searchText,
            pageNumber,
            pageSize,
        },
    });

    const data = response.data.page;

    // 返回分頁資訊和內容
    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
};