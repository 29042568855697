// src/endpoints.js
const API_BASE_URL = window._env_?.REACT_APP_GD_SYSHUB_BASE_URL;

const endpoints = {
    activityList: `${API_BASE_URL}/activity/list`,
    advertiseList: `${API_BASE_URL}/advertise/list`,
    bannerList: `${API_BASE_URL}/banner/list`,
    businessInfoList: `${API_BASE_URL}/businessInfo/list`,
    businessInfoArticle: `${API_BASE_URL}/businessInfo`,
    downloadTags: `${API_BASE_URL}/download/tags`,
    downloadList: `${API_BASE_URL}/download/list`,
    navigationList: `${API_BASE_URL}/menu/header`,
    newsPopular: `${API_BASE_URL}/news/popularNews`,
    news: `${API_BASE_URL}/news/listNews`,
    newsLatest: `${API_BASE_URL}/news/latestNews`,
    newsCategories: `${API_BASE_URL}/news/categories`,
    newsCategoryNews: `${API_BASE_URL}/news/categoryNews`,
    newsRecommend: `${API_BASE_URL}/news/recommendedNews`,
    newsArticle: `${API_BASE_URL}/news/newsArticle`,
    newsArticleRecommend: `${API_BASE_URL}/news/recommendedArticles`,
    successStoryList: `${API_BASE_URL}/successStory/list`,
    successStoryArticle: `${API_BASE_URL}/successStory`,
    searchList: `${API_BASE_URL}/searchContent/list`,
    homeVideo: `${API_BASE_URL}/homevideo/video`,
};

export default endpoints;
