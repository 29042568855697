// src/api/newsApi.js
import axios from 'axios';
import endpoints from '../endpoints';

export const fetchPopularNews = async (pageNumber, pageSize) => {
    const response = await axios.get(endpoints.newsPopular, {
        params: {
            pageNumber: pageNumber,
            pageSize: pageSize,
        },
    });
    return response.data.newsInfos;
};

export const fetchListNews = async (pageNumber, pageSize) => {
    const response = await axios.get(endpoints.news, {
        params: {
            pageNumber: pageNumber,
            pageSize: pageSize,
        },
    });
    return response.data.newsInfos;
};

export const fetchLatestNews = async () => {
    const response = await axios.get(endpoints.newsLatest);
    return response.data.newsInfos;
};

export const fetchCategories = async () => {
    const response = await axios.get(endpoints.newsCategories);
    return response.data.categories;
}

export const fetchCategoryNews = async (tagId, pageNumber = 0, pageSize = 5) => {
    const response = await axios.get(endpoints.newsCategoryNews, {
        params: {
            tagId,
            pageNumber,
            pageSize
        },
    });
    const data = response.data.page;

    // 返回分頁資訊和內容
    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
}

export const fetchRecommendNews = async () => {
    const response = await axios.get(endpoints.newsRecommend);
    return response.data.summaryNewsInfos;
}

export const fetchArticle = async (id) => {
    const response = await axios.get(endpoints.newsArticle, {
        params: {
            id,
        },
    });
    return response.data;
}

export const fetchArticleRecommend = async () => {
    const response = await axios.get(endpoints.newsArticleRecommend);
    return response.data.newsInfos;
}
