// src/pages/PrivacyPolicy.js
import React from 'react';
import {Footer, Header} from '../components';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <div className="mainInfo articleContent about_page">
                            <div className="articleTitle pt-3 pb-0">
                                <h1>隱私權政策</h1>
                            </div>
                            <div className="infoArea">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="infoList newsList">
                                            <section className="row article_con about_con">
                                                <p>財團法人中華民國對外貿易發展協會（包含具有關係企業關係之法人或組織團體，如台灣貿易中心等，詳官方網站，以下統稱「本會」）係受經濟部國際貿易署委託辦理推廣貿易工作業務而需要使用者(下稱「您」)提供個人資料。本會非常重視您的個人隱私並遵守「個人資料保護法」及其相關法律及規範
                                                    ，以確保落實隱私權保護。為了讓您更加瞭解本會網站(包括但不限於數位電商基地，網址 <a
                                                        href="https://gd.taiwantrade.com/">https://gd.taiwantrade.com/ </a>)
                                                    如何蒐集、處理、利用您所提供的個人資料，謹依中華民國個人資料保護法第8條規定告知隱私權政策(下稱「本政策」)。本會網站之所有使用者均應受本隱私權政策保護，且於開始使用本網站時，即表示同意本內容規定之隱私權政策。
                                                </p>
                                                <h3><strong>1.個人資料使用目的</strong></h3>
                                                <p>本會為協助廠商發展中華民國對外貿易、其他公共部門（包括行政法人、政府捐助財團法人及其他公法人）執行相關業務；貿易推廣及管理；網路購物及其他電子商務服務；客戶管理與服務；中小企業及其他產業之輔導；政令宣導；教育或訓練行政；圖書出版品管理；廣告或商業行為管理；行銷；契約、類似契約或其他法律關係事務；商業與技術資訊；資(通)訊服務；資(通)訊與資料庫管理；資通安全與管理；調查、統計與研究分析；以及非公務機關依法定義務等目的，蒐集並於前述目的範圍內利用您所提供之個人資料。。
                                                </p>
                                                <h3><strong>2.個人資料的範圍</strong></h3>
                                                <p>本政策適用範圍包括您因使用本會網站各項服務 (下稱「本服務」)
                                                    時所提供的個人資料。本服務包含但不限於會員服務、客戶服務及行銷活動等。</p>
                                                <h3><strong>3.個人資料的蒐集、處理及利用</strong></h3>
                                                <p>本會將透過下列方式蒐集您的個人資料，並僅將您的個人資料使用在您所需的服務上：</p>
                                                <ul>
                                                    <li aria-level="1">會員服務：註冊會員所提供的個人資料主要用於提供您更貼心多元的服務。</li>
                                                    <li aria-level="1">客戶服務：因使用客服所提供的個人資料主要用於回應您的詢問。</li>
                                                    <li aria-level="1">行銷活動：因參加行銷活動而提供的個人資料，僅供該活動使用。</li>
                                                </ul>
                                                <h3><strong>4.個人資料的蒐集類別</strong></h3>
                                                <p>透過上述資料蒐集方式，本會可能會蒐集到的個人資料包括：</p>
                                                <ul>
                                                    <li aria-level="1">C001
                                                        辨識個人者：包括姓名、性別、手機號碼、聯絡地址、電子郵件信箱、網際網路協定IP位址、Cookie及其他任何可辨識資料本人者等等資料。
                                                    </li>
                                                    <li aria-level="1">C003
                                                        政府資料中之辨識者：身分證統一編號、證照號碼、護照號碼等。
                                                    </li>
                                                    <li aria-level="1">C031 住家及設施：例如：地址。</li>
                                                    <li aria-level="1">C038 職業。</li>
                                                </ul>
                                                <p>若您不能提供相關服務範圍所需之個人資料，將有可能無法完成部份您所需之服務。</p>
                                                <h3><strong>5.個人資料利用期間、地區、對象及方式</strong></h3>
                                                <ul>
                                                    <li aria-level="1">(1) 期間： 本會將係指依照蒐集之特定目的、相關法令（例如：台灣民法、個人資料保護法等)
                                                        或契約所定、因本會執行業務所須之必要保存期間（較法規規定期間長者）、另經您同意之較長期間內持續使用您提供的個人資料，直至您提出停止使用或本會停止提供服務之日為止。
                                                    </li>
                                                    <li aria-level="1">(2) 地區：
                                                        包括中華民國、本會各駐點及其轄區之國家、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者所在地、個人資料利用對象之所在地，以及本會業務往來機構之所在地。
                                                    </li>
                                                    <li aria-level="1">(3) 對象：
                                                        您的資料可能會提供給本會的代理人、簽約人、提供線上付款認證或其他相關服務之服務供應商、及合作夥伴（例如PayPal、網際威信、各收單銀行、各支付或收付款服務提供機構、各物流業者、各認證服務業者、eBay及其他與本會合作的交易平臺）、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者、其他與本會有業務往來之機構、法院、及/或政府主管機關。
                                                    </li>
                                                    <li aria-level="1">(4) 方式：
                                                        以數位檔案、實體紙本及其他合於當時科學技術之適當方式，包含以自動化機器或其他非自動化之方式為蒐集、處理、利用與國際傳輸。
                                                    </li>
                                                </ul>
                                                <h3><strong>6.客戶資料儲存及保管方式</strong></h3>
                                                <p>您的個人資料均被嚴密地保存在本會的資料庫系統中。同時，任何人均需在本會訂定之資料授權管理規範下，進行資料之取得與使用，而不在授權範圍內之任何人，均不得亦無法通過授權控管系統而取得資料。</p>
                                                <h3><strong>7.個人資料揭露對象</strong></h3>
                                                <p>本會保證在未經您的同意下，不會將您的個人資料揭露於與本服務無關之第三人或上述目的以外之用途。惟有下列三種情形，我們會與第三人揭露您的個人資料：</p>
                                                <ul>
                                                    <li aria-level="1">經過您的事前同意或授權允許時。</li>
                                                    <li aria-level="1">司法單位或其他主管機關經合法正式的程序要求時。</li>
                                                    <li aria-level="1">為了提供您其他服務，需要與提供該服務之第三者共用您的資料時，我們會提供充分說明並告知，您可以自由選擇是否接受這項服務。</li>
                                                </ul>
                                                <h3><strong>8.個人資料的權利行使</strong></h3>
                                                <p>依個人資料保護法第3條之規定得行使以下權利：</p>
                                                <ul>
                                                    <li aria-level="1">查詢或請求閱覽。</li>
                                                    <li aria-level="1">請求製給複製本。</li>
                                                    <li aria-level="1">請求補充或更正。</li>
                                                    <li aria-level="1">請求停止蒐集、處理或利用。</li>
                                                    <li aria-level="1">請求限制處理。</li>
                                                    <li aria-level="1">限制自動決策。</li>
                                                    <li aria-level="1">請求刪除。但若該個人資料處理係屬必要而依相關個資法令，該等資料不適用於刪除權之行使時，本會可能因此無法進行作業及回覆。</li>
                                                </ul>
                                                <p>您行使上述權利時，應填具申請文件(點擊此連結下載「個人資料當事人權利行使申請單」)，申請表填寫完畢後，請將申請表以郵寄方式提交給本會。本會得向您請求提出可資確認之身分證明文件;若委託他人代為申請者，並應出具委任書，且提供本人及代理人之身分證明文件。
                                                </p>
                                                <p>若您就您的會員註冊資料向本會請求答覆查詢、提供閱覽或製給複製本時，本會得酌收必要成本費用。</p>
                                                <p>若您的個人資料有變更，您可自行於本會網站進行更正或致電 0800-506-088
                                                    修正您的個人資料。</p>
                                                <p>如果您所提供的申請表資料不正確或不完整（如使用暱稱），致本會無法確認您的真實身分，本會將無法受理您的權利行使主張。</p>
                                                <h3><strong>9.自我保護</strong></h3>
                                                <p>請妥善保管您的任何個人資料、附加服務與各類網路服務，不要將任何個人資料提供給任何人。在您完成本會服務等相關資訊變動等程序後，務必記得登出，若您是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗，以防止他人蒐集並使用您的個人資料。
                                                </p>
                                                <h3><strong>10.隱私保護諮詢</strong></h3>
                                                <p>如果您對於我們的隱私權政策或是有個人資料蒐集、利用、更新等問題，請於上班時間聯絡：</p>
                                                <ul>
                                                    <li aria-level="1">服務時間：週一至週五09:00-17:00</li>
                                                    <li aria-level="1">免費諮詢專線：0800-506-088</li>
                                                    <li aria-level="1">聯絡信箱： member@taiwantrade.com</li>
                                                </ul>
                                                <h3><strong>11.隱私權政策修訂</strong></h3>
                                                <p>本政策將需求更新擴充而作適當調整、或因應業務及法令之需求進行其他不定時修訂，當本政策變更時，新的內容將會呈現於此網頁。當本會在使用個人資料的規定上作出大幅修改時，為維護會員權益，本會會在網頁上張貼告示，以讓會員知曉相關權益事項。本會亦將定期檢視隱私權保護/安全聲明以符合相關法律規範，並保留隨時修改本隱私權政策的權利。建議您於使用本服務前，再次閱讀而瞭解本會所發佈之本政策最新版本。
                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default PrivacyPolicy;