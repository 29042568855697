// src/hooks/useBusinessInfo.js
import {useQuery} from 'react-query';
import {fetchBusinessInfo, fetchBusinessInfoArticle} from '../api/businessInfoApi';

export const useBusinessInfo = (pageNumber, pageSize) => {
    return useQuery(['businessInfo', pageNumber, pageSize], () => fetchBusinessInfo(pageNumber, pageSize), {
        refetchOnWindowFocus: false, // 根據需求調整
    });
};

export const useBusinessInfoArticle = (id, type) => {
    return useQuery(
        ['businessInfoArticle', id],
        () => fetchBusinessInfoArticle(id),
        {
            refetchOnWindowFocus: false,
            enabled: !!id && type === 'information', // Only fetch if id exists and type is 'information'
        }
    );
};