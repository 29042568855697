// src/components/PageNav.js
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Loading} from "./index";
import {useCategories} from "../hooks/useNews";
import {$} from "react-jquery-plugin";
import {categories} from '../utils/categories';

const PageNav = ({ selectedCategory = "" }) => {
    const { data, isLoading, isError } = useCategories();
    const subCategories = data;

    useEffect(() => {
        $('.navTitle').on('click', function () {
            $(this).siblings('ul').slideToggle(300);
        });

        // Cleanup on component unmount
        return () => {
            $('.navTitle').off('click');
        };
    }, []);

    return (
        <aside className="pageNav">
            <div className="navTitle">
                <div>
                    <span>文章分類</span>
                    <i className="bi bi-caret-down"></i>
                </div>
            </div>
            <ul>
                {categories.map((category, index) => (
                    <li key={index}>
                        <Link to={category.link} className={category.name === selectedCategory ? 'active' : ''}>
                            <span>{category.name}</span>
                        </Link>
                        {category.name === '分類文章' && subCategories && (
                            <ul>
                                {subCategories.map((subcategory, subIndex) => (
                                    <li key={subIndex}>
                                        <Link to={`/newsAll?tagId=${subcategory.id}`}>
                                            ・<span>{subcategory.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
                {isLoading && <Loading/>}
                {isError && <p>Error loading categories</p>}
            </ul>
        </aside>
    );
};

export default PageNav;
