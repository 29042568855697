// src/utils/mock/mockNavData.js
export const mockNavData = []
/*
[
    {
        title: "DATE",
        link: "",
        type: "out",
        subItems: [
            {title: "DATE官網", link: "https://www.datesummit.com/", type: "out"},
            {title: "立刻報名", link: "https://www.accupass.com/event/2407100629363613327320", type: "out"}
        ]
    },
    {
        title: "關於我們",
        link: "/about",
        type: "in",
        subItems: []
    },
    {
        title: "知識補給",
        link: "",
        type: "in",
        subItems: [
            {title: "新知脈動", link: "/newsAll", type: "in"},
            {title: "商情資訊", link: "/information", type: "in"},
            {title: "成功案例", link: "/case", type: "in"}
        ]
    },
    {
        title: "數位學習",
        link: "",
        type: "out",
        subItems: [
            {title: "數位貿易學苑", link: "https://elearning.taiwantrade.com", type: "out"},
            {title: "電商研討會", link: "https://info.taiwantrade.com/promotion/event#menu=11608", type: "out"},
            {title: "活動資訊", link: "https://events.taiwantrade.com/", type: "out"}
        ]
    },
    {
        title: "電商輔導",
        link: "",
        type: "out",
        subItems: [
            {title: "跨境電商上架", link: "https://info.taiwantrade.com/index/ecplatform#menu=11612", type: "out"},
            {title: "中小企業專案", link: "https://digitalcommerce.taiwantrade.com/ai.html", type: "out"},
            {title: "數位行銷健檢", link: "https://events.taiwantrade.com/checkup", type: "out"},
            {title: "說明暨洽談會", link: "", type: "out"},
            {title: "商品認證檢測", link: "https://info.taiwantrade.com/index/service_special#wa2", type: "out"},
            {title: "電商實戰營", link: "https://info.taiwantrade.com/memberindex/ttcampus", type: "out"}
        ]
    },
    {
        title: "影音專區",
        link: "",
        type: "out",
        subItems: [
            {title: "影音最前線", link: "https://info.taiwantrade.com/video/#menu=11610", type: "out"},
            {title: "Youtube", link: "https://www.youtube.com/taiwantrade", type: "out"}
        ]
    }
];
*/
