// src/pages/About.js
import React from 'react';
import {Footer, Header} from '../components';
import aboutDesktopImg from '../assets/img/about-desktop.80000b5.png';
import aboutKnowledgeImg from '../assets/img/about-knowledge.b9c4535.png';
import aboutLearningImg from '../assets/img/about-learning.793695c.png';
import aboutServiceImg from '../assets/img/about-service.b4a8fa9.png';

const About = () => {
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <div className="mainInfo articleContent about_page">
                            <div className="articleTitle">
                                <h5>關於數位電商基地</h5>
                                <h1>聚焦數位行銷與跨境電商領域，<br/>
                                    為臺灣中小企業植入數位DNA，拓展國際貿易商機。</h1>
                            </div>
                            <div className="infoArea">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="infoList newsList">
                                            <section className="row article_con about_con">
                                                <img className="mb-3"
                                                     src={aboutDesktopImg}
                                                     alt=""/>
                                                <p className="my-3">線上購物已是全球消費市場一大趨勢，進而帶動產品銷售轉向跨境經營的數位貿易模式。因應數位化經濟浪潮，跨境電商也成為臺灣企業經營海外市場的最佳策略及通路之一！
                                                    <br/>
                                                    為協助臺灣業者運用跨境電商拓展國際市場，外貿協會台灣經貿網推出「數位電商基地」網站，集結最新商情資訊、市場趨勢、電商及數位行銷新知，打造一站式電商資訊平台，更提供充沛輔導資源及服務，協助臺灣廠商儲備電商實戰力，輕鬆跨足海外銷售市場並創造佳績！
                                                </p>
                                                <div className="row about_info">
                                                    <div className="col-12 col-lg-6 order-sm-1 order-lg-2">
                                                        <img
                                                            src={aboutKnowledgeImg}
                                                            alt=""/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 order-sm-2 order-lg-1 info_txt">
                                                        <h2><span>01</span>知識補給</h2>
                                                        <p>想拓展海外市場，但不確定從哪裡出發嗎？選定了市場，又不知該如何開始經營電商嗎？知識補給集結最新商情、市場趨勢、成功案例、電商及數位行銷密技，是您快速掌握國際市場情報、輕鬆學習電商與行銷新知的第一站！</p>
                                                    </div>
                                                </div>
                                                <div className="row about_info">
                                                    <div className="col-12 col-lg-6 order-sm-1 order-lg-1">
                                                        <img
                                                            src={aboutLearningImg}
                                                            alt=""/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 order-sm-2 order-lg-2 info_txt">
                                                        <h2><span>02</span>數位學習</h2>
                                                        <p>吸收了電商與行銷新知，卻不知道怎麼運用嗎？數位學習彙整台灣經貿網線上及線下活動與多元學習資源，讓您透過數位行銷、跨境電商、數位轉型及數位商務等4大主題課程，並運用實體課程、線上課程、實際操作等3大上課方式，持續加深您的數位電商力！</p>
                                                    </div>
                                                </div>
                                                <div className="row about_info">
                                                    <div className="col-12 col-lg-6 order-sm-1 order-lg-2">
                                                        <img
                                                            src={aboutServiceImg}
                                                            alt=""/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 order-sm-2 order-lg-1 info_txt">
                                                        <h2><span>03</span>電商輔導</h2>
                                                        <p>開始經營跨境電商後，該如何進步、創造更多商機呢？電商輔導整合電商上架、行銷健檢、產學合作等多項輔導資源，幫您分析與解決各種疑難雜症，更協助您達成目標、佈局與精進營運策略，全方位提升經營成效！</p>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default About;
