// src/api/businessInfoApi.js
import axios from 'axios';
import endpoints from '../endpoints';

const LIST_URL = 'http://127.0.0.1:8080/gdSystemHub/gdweb/businessInfo/list';
const GET_URL = 'http://127.0.0.1:8080/gdSystemHub/gdweb/businessInfo';

export const fetchBusinessInfo = async (pageNumber = 0, pageSize = 5) => {
    const response = await axios.get(endpoints.businessInfoList, {
        params: {
            pageNumber,
            pageSize,
        },
    });

    const data = response.data.page;

    // 返回分頁資訊和內容
    return {
        content: data.content,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        pageNumber: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize,
    };
};

export const fetchBusinessInfoArticle = async (id = undefined) => {
    if (!id) {
        throw new Error('BusinessInfo Article ID is required');
    }

    const response = await axios.get(`${endpoints.businessInfoArticle}/${id}`);
    return response.data;
};