// src/components/Error.js
import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from "prop-types";

const ErrorAlert = ({ message = "發生錯誤，請稍後再試。" }) => (
    <Alert variant="danger" className="text-center my-4">
        {message}
    </Alert>
);

ErrorAlert.propTypes = {
    message: PropTypes.string
};

export default ErrorAlert;
