// src/pages/Faq.js
import React from 'react';
import {Footer, Header} from '../components';

const Faq = () => {
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="container">
                    <div className="tab-content">
                        <div className="mainInfo articleContent about_page">
                            <div className="articleTitle pt-3 pb-0">
                                <h1>網站與服務Q&A</h1>
                            </div>
                            <div className="infoArea">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="infoList newsList">
                                            <section className="row article_con about_con">
                                                <h3><strong>網站使用</strong></h3>
                                                <h5><i className="bi bi-chat-right-quote"></i>「數位電商基地」提供哪些內容與服務？
                                                </h5>
                                                <ul>
                                                    <li>【知識補給】
                                                        <span className="ml-2">集結最新商情、市場趨勢、成功案例、電商及數位行銷密技，是您快速掌握國際市場情報、輕鬆學習電商與行銷新知的第一站！</span></li>
                                                    <li>【數位學習】
                                                        <span>彙整台灣經貿網線上及線下活動與多元學習資源，讓您透過數位行銷、跨境電商、數位轉型及數位商務等4大主題課程，並運用實體課程、線上課程、實際操作等3大上課方式，持續加深您的數位電商力！</span></li>
                                                    <li>【電商輔導】
                                                        <span>整合電商上架、行銷健檢、產學合作等多項輔導資源，幫您分析與解決各種疑難雜症，更協助您達成目標、佈局與精進營運策略，全方位提升經營成效！</span>
                                                    </li>
                                                    <li>【影音專區】
                                                        <span>收錄台灣經貿網各式活動集錦與花絮，串聯您的學習足跡，更能見證與重溫您參與活動的成長與感動，持續引領您邁向成功的數位貿易之路！</span>
                                                    </li>
                                                </ul>

                                                <h5><i className="bi bi-chat-right-quote"></i>「數位電商基地」網站的最佳瀏覽方式為何？
                                                </h5>
                                                <ul>
                                                    <li>
                                                        目前支援的網站瀏覽器，包括：最新版本 Chrome、最新版本 Firefox、最新版本 Safari 、 iOS13 以上、Android7.0 以上和最新版本 Edge，不支援 IE。此外，螢幕最佳瀏覽解析度建議為1024 &times;768。
                                                    </li>
                                                </ul>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Faq;
